<template>
  <div class="buy-offer">
    <!--TODO: añadir condiciones para mostrar/ocultar las tabs cuando proceda-->
    <ul class="buy-offer__tabs">
      <li class="buy-offer__tab">
        <button
          class="buy-offer__tab-button"
          :class="{
            'buy-offer__tab-button--active': tabActive === 'MarketPrice',
          }"
          type="button"
          @click="changeTab('MarketPrice')"
        >
          {{ $t('buyAtPrice') }}
        </button>
      </li>

      <li class="buy-offer__tab">
        <tippy arrow arrow-type="round" theme="dark">
          <template v-slot:trigger>
            <!-- add @click="changeTab('OfferPrice')" in future-->
            <button
              class="buy-offer__tab-button"
              :class="{
                'buy-offer__tab-button--active': tabActive === 'OfferPrice',
              }"
              type="button"
            >
              {{ $t('makeOffer') }}
            </button>
          </template>
          {{ $t('notAvailable') }}
        </tippy>
      </li>

      <li class="buy-offer__tab">
        <tippy arrow arrow-type="round" theme="dark">
          <template v-slot:trigger>
            <!-- add @click="changeTab('MarketOffers')" in future-->
            <button
              class="buy-offer__tab-button"
              :class="{
                'buy-offer__tab-button--active': tabActive === 'MarketOffers',
              }"
              type="button"
            >
              {{ $t('browseOffers') }}
            </button>
          </template>
          {{ $t('notAvailable') }}
        </tippy>
      </li>
    </ul>

    <div v-if="tabActive === 'MarketPrice'" class="buy-offer__layer">
      <BuyAtMarketPrice
        :enableQuadrants="enableQuadrants"
        :marketPrice="marketPrice"
        :available="available"
        :currency="currency"
        :id="id"
        :title="title"
        :artist="artist"
      >
      </BuyAtMarketPrice>
    </div>
    <div v-if="tabActive === 'OfferPrice'" class="buy-offer__layer">
      <BuyAtOfferPrice>
        <v-btn :to="{ name: 'checkout', params: { id: id } }" color="primary">
          {{ $t('makeOffer') }}
        </v-btn>
      </BuyAtOfferPrice>
    </div>
    <div v-if="tabActive === 'MarketOffers'" class="buy-offer__layer">
      <div class="heading-size-4 mb-8">Browse Offers</div>
      <BuyAtMarketOffers
        :data="offers"
        :marketPrice="info.currentUnitValue"
        :currency="currency"
      >
        <v-btn :to="{ name: 'checkout', params: { id: id } }" color="primary">
          {{ $t('buy') }}
        </v-btn>
      </BuyAtMarketOffers>
    </div>
  </div>
</template>

<script>
import BuyAtMarketPrice from '@/components/Organisms/BuyAtMarketPrice';
import BuyAtOfferPrice from '@/components/Organisms/BuyAtOfferPrice';
import BuyAtMarketOffers from '@/components/Organisms/BuyAtMarketOffers';

export default {
  name: 'BuyOffer',
  props: {
    title: String,
    artist: String,
    enableQuadrants: Boolean,
    backFrames: { type: Number, default: 0 },
    id: String,
    offers: Array,
    marketPrice: { type: Number, default: 0 },
    currency: String,
    available: { type: Number, default: 0 },
    tabActive: {
      validator: function(value) {
        // El valor debe coincidir con una de estas cadenas de texto
        return ['MarketPrice', 'OfferPrice', 'MarketOffers'].includes(value);
      },
    },
  },
  components: {
    BuyAtMarketPrice,
    BuyAtOfferPrice,
    BuyAtMarketOffers,
  },
  data() {
    return {};
  },
  methods: {
    changeTab(value) {
      this.$emit('changeTab', value);
    },
  },
  beforeMount() {
    this.$store.commit('refreshWebsiteVersion');
  },
};
</script>

<style lang="scss" scoped>
.buy-offer {
  $this: &;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &__tabs {
    margin: 0 -8px 24px;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
  &__tab {
    margin: 0 2px;
  }
  &__tab-button {
    font-weight: 400;
    padding: 12px;
    border-radius: 4px;
    transition: all 0.4s linear;
    font-weight: 600;

    @media only screen and (max-width: 767px) {
      font-size: 12px;
    }
    &--active,
    &:hover {
      color: var(--color-black);
      background-color: var(--color-primary);
    }
  }

  &__layer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    .neo-card {
      @media only screen and (min-width: 768px) {
        width: 50%;
      }
    }
  }
}
</style>
