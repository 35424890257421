<template>
  <two-columns-center>
    <template slot="col-1">
      <BuyWork
        class="buy-frames__work"
        :img="mainImage"
        :title="artworkInfo.artwork"
        :artist="artworkInfo.artist"
        :transactions="transactions"
        :info="artworkMetrics"
      />
    </template>
    <template slot="col-2">
      <BuyOffer
        :id="id"
        :tab-active="buyOfferTabActive"
        :offers="offers"
        :marketPrice="artworkMetrics.currentFrameValue"
        :currency="artworkMetrics.currency"
        :available="artworkMetrics.availableFrames"
        :enableQuadrants="enableQuadrants"
        :title="artworkInfo.artwork"
        :artist="artworkInfo.artist"
        @changeTab="changeTab($event)"
      />
    </template>
  </two-columns-center>
</template>

<script>
import TwoColumnsCenter from "@/components/Templates/TwoColumnsCenter";
import BuyWork from "@/components/Organisms/BuyWork";
import BuyOffer from "@/components/Organisms/BuyOffer";

import ArtworkDS from "@/services/ArtworkDS";

export default {
  name: "buyFramesPage",
  components: {
    TwoColumnsCenter,
    BuyWork,
    BuyOffer,
  },
  props: {
    id: String,
  },
  data: () => ({
    enableQuadrants: false,
    totalFrames: 1,
    artworkInfo: {},
    artworkMetrics: { availableFrames: 0 },
    mainImage: "",
    buyOfferTabActive: "MarketPrice",
    offers: [
      {
        collector: "collector1",
        offer: 130,
        quantity: 5,
        currency: "$",
        id: 1,
      },
      {
        collector: "collector2",
        offer: 120,
        quantity: 3,
        currency: "$",
        id: 2,
      },
      {
        collector: "collector3",
        offer: 105,
        quantity: 25,
        currency: "$",
        id: 3,
      },
    ],
    transactions: {
      labels: [
        "<1,000",
        "1,000-1,300",
        "1,300-1,500",
        "1,500-2,000",
        "2,000-3,000",
        ">3,000",
      ],
      values: [0, 1000, 0, 0, 0, 0],
    },
    artworkRef: undefined,
    artworkSubscriber: undefined,
  }),
  async beforeMount() {
    // Get artwork info
    this.mainImage = `${process.env.VUE_APP_IMAGES_URL}artwork/${this.id}/${this.id}_0.jpg`;
    const artworkSnapshot = await ArtworkDS.getArtworkInfo(this.id);

    this.artworkInfo = artworkSnapshot.val();

    // Subscribe to artwork metrics
    this.artworkRef = ArtworkDS.refArtworkMetrics(this.id);
    this.artworkSubscriber = this.artworkRef.on("value", (snapshot) => {
      this.artworkMetrics = snapshot.val();
      if (this.artworkMetrics.qs)
        this.enableQuadrants = true;
    });
  },
  methods: {
    changeTab(value) {
      this.buyOfferTabActive = value;
    },
  },
};
</script>
