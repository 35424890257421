var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buy-market-offers"},[_c('v-radio-group',{staticClass:"ma-0 pa-0",model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},[_c('v-data-table',{staticClass:"elevation-1 neo-table",attrs:{"headers":_vm.headers,"items":_vm.data,"single-expand":_vm.singleExpand,"item-key":_vm.data.id,"show-expand":"","mobile-breakpoint":"0"},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.collector",fn:function(ref){
var item = ref.item;
return [_c('v-radio',{on:{"click":function($event){return _vm.selectOffer(item)}}},[_c('span',{staticClass:"caption",attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(item.collector))])])]}},{key:"item.offer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(item.currency)+_vm._s(item.offer))]),_c('v-icon',{attrs:{"large":"","color":_vm.getColor(_vm.marketPrice - item.offer)}},[_vm._v(" "+_vm._s(_vm.getIcon(_vm.marketPrice - item.offer))+" ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"neo-card"},[_c('v-form',[_c('div',{staticClass:"mb-6"},[(!_vm.offerCollector)?[_vm._v(" "+_vm._s(_vm.selectedCollector)+" ")]:[_c('div',[_vm._v(" You have selected this collector: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.offerCollector))])]),(item.collector !== _vm.offerCollector)?_c('div',[_vm._v(" Click in the row for change if you want. ")]):_vm._e()]],2),_c('div',{staticClass:"neo-text-field"},[_c('div',[_vm._v("Offer")]),_c('v-text-field',{attrs:{"label":"Offer"},model:{value:(_vm.offer),callback:function ($$v) {_vm.offer=$$v},expression:"offer"}})],1),_c('div',{staticClass:"neo-text-field"},[_c('div',[_vm._v("Number of Frames")]),_c('v-text-field',{attrs:{"label":"Number of Frames"},model:{value:(_vm.frames),callback:function ($$v) {_vm.frames=$$v},expression:"frames"}})],1),_c('CalculatedPrice',{attrs:{"currency":_vm.currency,"value":_vm.calculatedPrice}})],1)],1)])]}}],null,true)})],1),_c('div',{staticClass:"text-center mt-4"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }