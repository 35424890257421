<template>
  <div class="neo-card">
    <v-form>
      <div class="neo-text-field">
        <div>{{ $t("numberFrames") }}</div>
        <v-text-field
          :disabled="this.available === 0"
          v-model="frames"
          type="number"
          min="0"
          :label="$t('numberFrames')"
          required
          @input="listeningFrames"
        ></v-text-field>
      </div>
      <div class="areaGroup">
        <v-checkbox
          v-if="enableQuadrants"
          :label="$t('chooseArea')"
          v-model="chooseArea"
          :disabled="frames < 10"
        />
        <tippy
          v-if="enableQuadrants"
          class="frames-tippy"
          placement="top-start"
          theme="dark"
        >
          <template v-slot:trigger>
            <v-icon small>mdi-information-outline</v-icon>
          </template>
          {{ $t("chooseAreaMsg") }}
        </tippy>
      </div>
      <CalculatedPrice :currency="currency" :value="calculatedPrice" />
      <div class="text-center mt-4">
        <v-btn
          @click="goToNextPage()"
          :disabled="
            framesToNumber > limitFrame ||
            framesToNumber <= 0 ||
            framesToNumber > maxOwnership
          "
          color="primary"
        >
          {{ $t("buy") }}
        </v-btn>
      </div>
    </v-form>
    <NeoModal
      v-if="showAdviseModal"
      :max-width="400"
      title-header="Notice"
      @close="closeAdviseModal"
    >
      <v-card-text>
        <p class="text-center mt-6">
          {{ message }}
        </p>
      </v-card-text>
      <template slot="actions">
        <v-btn
          v-if="this.limitFrame === this.hardFrameLimit"
          @click="contactUs"
          class="mr-6"
          outlined
          color="primary"
        >
          {{ $t("contactUs") }}
        </v-btn>
        <v-btn
          v-else
          @click="cancelModal"
          class="mr-6"
          outlined
          color="primary"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="closeAdviseModal"
          >{{ $t("buy") }} {{ limitFrame }} {{ $t("frames") }}</v-btn
        >
      </template>
    </NeoModal>
  </div>
</template>
<script>
import CalculatedPrice from "@/components/Molecules/CalculatedPrice";
import NeoModal from "@/components/Molecules/NeoModal";
import UsersDS from "@/services/UsersDS";
import ArtworkDS from "@/services/ArtworkDS";
import { analytics } from "@/helpers/firebase";

export default {
  name: "BuyAtMarketPrice",
  components: {
    CalculatedPrice,
    NeoModal,
  },
  props: {
    id: String,
    marketPrice: { type: Number, default: 0 },
    available: { type: Number, default: 1 },
    tax: { type: Number, default: 0.1 },
    currency: String,
    enableQuadrants: Boolean,
    title: String,
    artist: String,
  },
  data() {
    return {
      maxOwnership: 1,
      totalFrames: 1,
      message: "",
      showAdviseModal: false,
      chooseArea: false,
      frames: 10,
      limitFrame: 251,
      hardFrameLimit: 251,
      owned: 0,
      ready: false,
    };
  },
  watch: {
    available() {
      if (this.ready) this.listeningFrames();
    },
  },
  methods: {
    contactUs() {
      this.$router.push({
        name: "contact",
      });
    },
    cancelModal() {
      this.frames = 1;
      this.showAdviseModal = false;
    },
    closeAdviseModal() {
      this.showAdviseModal = false;
      this.frames = this.limitFrame;
    },
    goToNextPage() {
      analytics.logEvent("select_item", {
        item_list_id: this.id,
        item_list_name: `${this.artist} - ${this.title}`,
        items: [
          {
            item_id: this.id,
            item_name: `${this.artist} - ${this.title}`,
            currency: this.currency,
            item_variant: `${this.chooseArea}`,
            price: this.marketPrice,
            quantity: Number(this.frames),
          },
        ],
      });
      this.$router.push({
        name: this.chooseArea ? "work.quadrants" : "checkout",
        params: { id: this.id, quantity: Number(this.frames) },
      });
    },
    listeningFrames() {
      if (!this.available) {
        this.limitFrame = 0;
        return;
      }

      if (this.frames < 10) {
        this.chooseArea = false;
      }

      this.maxOwnership = Math.max(
        0,
        Math.floor(this.totalFrames * 0.35) - this.owned
      );
      const minFramesArray = [
        this.maxOwnership,
        this.hardFrameLimit,
        this.framesToNumber,
        this.available,
      ];

      const minValueIndex = minFramesArray.indexOf(Math.min(...minFramesArray));
      this.limitFrame = this.available;
      if (this.maxOwnership === 0) {
        this.$store.commit("ALERT_MESSAGE", {
          msg: this.$t("cantBuyMore"),
        });
      } else if (minValueIndex === 0 && this.owned !== 0) {
        this.limitFrame = Math.floor(this.totalFrames * 0.35) - this.owned;
        if (this.$store.state.lang === "ja") {
          this.message = `${this.limitFrame} ${this.$t(
            "frameOwnershipLimitMsg"
          )}`;
        } else {
          this.message = `${this.$t("youCanOnlyBuy")} ${
            this.limitFrame
          } ${this.$t("frameOwnershipLimitMsg")}`;
        }
        this.showAdviseModal = true;
      } else if (minValueIndex === 1) {
        this.limitFrame = this.hardFrameLimit - 1;
        if (this.$store.state.lang === "ja") {
          this.message = `${this.hardFrameLimit - 1} ${this.$t(
            "frameLimitMsg"
          )}`;
        } else {
          this.message = `${this.$t("frameLimitMsg")} ${
            this.hardFrameLimit - 1
          } ${this.$t("frames")}`;
        }
        this.showAdviseModal = true;
      } else if (minValueIndex === 3) {
        if (this.$store.state.lang === "ja") {
          this.message = `${this.available} ${this.$t("frames")} ${this.$t(
            "thereAreOnly"
          )}`;
        } else {
          this.message = `${this.$t("thereAreOnly")} ${
            this.available
          } ${this.$t("frames")} ${this.$t("available")}`;
        }
        this.showAdviseModal = true;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("ALERT_MESSAGE", {
      msg: "",
    });
  },
  async mounted() {
    if (this.$store.state.isVerified) {
      // Get total number of frames and currently
      const snap = await UsersDS.getCollectorsArtworkInfoRT(this.id);
      if (snap.exists()) {
        this.owned = snap.val().quantity;
      } else {
        this.owned = 0;
      }
      const snapTotal = await ArtworkDS.getArtworkTotalFrames(this.id);
      if (snapTotal.exists()) {
        this.totalFrames = snapTotal.val() || 0;
        this.listeningFrames();
        this.ready = true;
      }
    } else {
      this.$router.push(`/${this.$store.state.lang}/artwork/`);
    }
  },
  computed: {
    framesToNumber() {
      return parseInt(this.frames) || 0;
    },
    // calculatedPrice() {
    //   const breakdown = {
    //     frames: this.frames,
    //   };
    //   const subtotal = this.frames*this.marketPrice;
    //   (breakdown["frameValue"] = this.marketPrice),
    //     (breakdown["subtotal"] = subtotal);
    //   breakdown["tax"] = subtotal*this.tax;
    //   breakdown["total"] = subtotal*(1 + this.tax);
    //   console.log(breakdown)
    //   return breakdown;
    // },
    calculatedPrice() {
      const subtotal = this.frames*this.marketPrice;
      return {
        frames: this.frames,
        frameValue: this.marketPrice,
        frameTax: this.marketPrice*this.tax,
        totalTaxInc: subtotal*(1 + this.tax) ,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.areaGroup {
  display: flex;
}
</style>
