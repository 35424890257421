<template>
  <div class="neo-card">
    <v-form>
      <div class="neo-text-field">
        <div>Offer</div>
        <v-text-field v-model="offer" label="Offer"></v-text-field>
      </div>
      <div class="neo-text-field">
        <div>Number of Frames</div>
        <v-text-field v-model="frames" label="Number of Frames"></v-text-field>
      </div>
      <CalculatedPrice :currency="currency" :value="calculatedPrice" />
      <div class="text-center mt-4">
        <slot></slot>
      </div>
    </v-form>
  </div>
</template>

<script>
import CalculatedPrice from "@/components/Molecules/CalculatedPrice";
export default {
  name: "BuyAtMarketPrice",
  components: {
    CalculatedPrice,
  },
  data() {
    return {
      frames: 1,
      offer: 100,
      marketPrice: 100,
      currency: "$",
    };
  },
  computed: {
    calculatedPrice() {
      return {
        Frames: this.frames,
        offer: this.offer,
        Total: this.frames * this.offer,
      };
    },
  },
};
</script>
