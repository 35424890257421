<template>
  <div class="buy-work">
    <figure class="buy-work__card buy-work__figure">
      <img :src="img" />
    </figure>
    <div class="buy-work__title">
      <div class="heading-size-4">{{ title }}</div>
      <div class="heading-size-4">{{ artist }}</div>
    </div>
    <div class="buy-work__graph">
      <div class="text-uppercase">{{ $t('priceEvolution') }}</div>
      <GraphTransactions
        :labels="transactions.labels"
        :values="transactions.values"
      />
    </div>
    <div class="buy-work__price">
      <PriceUnitCurrent
        tiny
        :currency="info.currency"
        :valueOriginal="info.previousFrameValue"
        :valueCurrent="info.currentFrameValue"
      />
    </div>
    <div class="buy-work__total">
      <TotalUnits
        tiny
        :value="info.availableFrames"
        :total="info.totalFrames"
      />
    </div>
  </div>
</template>

<script>
import GraphTransactions from '@/components/Organisms/GraphTransactions';
import PriceUnitCurrent from '@/components/Molecules/PriceUnitCurrent';
import TotalUnits from '@/components/Molecules/TotalUnits';
export default {
  name: 'BuyWork',
  components: {
    GraphTransactions,
    PriceUnitCurrent,
    TotalUnits,
  },
  props: {
    img: String,
    artist: String,
    title: String,
    transactions: Object,
    info: Object,
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.buy-work {
  $this: &;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'title title'
    'figure figure'
    'total price'
    'graph graph';

  &__title,
  &__figure,
  &__graph,
  &__price,
  &__total {
    border-radius: 4px;
    background: var(--color-white);
    padding: 12px;
  }
  &__title {
    grid-area: title;
  }
  &__graph {
    grid-area: graph;
  }
  &__price {
    grid-area: price;
  }
  &__total {
    grid-area: total;
  }
  &__figure {
    grid-area: figure;
    max-height: 280px;
    display: flex;
    justify-content: center;
    img {
      object-fit: contain;
    }
  }
}
</style>
