<template>
  <div class="buy-market-offers">
    <v-radio-group v-model="radioGroup" class="ma-0 pa-0">
      <v-data-table
        :headers="headers"
        :items="data"
        class="elevation-1 neo-table"
        :single-expand="singleExpand"
        :item-key="data.id"
        show-expand
        mobile-breakpoint="0"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template v-slot:[`item.collector`]="{ item }">
          <v-radio @click="selectOffer(item)">
            <span slot="label" class="caption">{{ item.collector }}</span>
          </v-radio>
        </template>
        <template v-slot:[`item.offer`]="{ item }">
          <div class="d-flex align-center">
            <div>{{ item.currency }}{{ item.offer }}</div>
            <v-icon large :color="getColor(marketPrice - item.offer)">
              {{ getIcon(marketPrice - item.offer) }}
            </v-icon>
          </div>
        </template>

        <!--caja expandible-->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="neo-card">
              <v-form>
                <div class="mb-6">
                  <template v-if="!offerCollector">
                    {{ selectedCollector }}
                  </template>
                  <template v-else>
                    <div>
                      You have selected this collector:
                      <span class="font-weight-bold">{{ offerCollector }}</span>
                    </div>
                    <div v-if="item.collector !== offerCollector">
                      Click in the row for change if you want.
                    </div>
                  </template>
                </div>

                <div class="neo-text-field">
                  <div>Offer</div>
                  <v-text-field v-model="offer" label="Offer"></v-text-field>
                </div>
                <div class="neo-text-field">
                  <div>Number of Frames</div>
                  <v-text-field
                    v-model="frames"
                    label="Number of Frames"
                  ></v-text-field>
                </div>
                <CalculatedPrice
                  :currency="currency"
                  :value="calculatedPrice"
                />
              </v-form>
            </div>
          </td>
        </template>
        <!--fin caja expandible-->
      </v-data-table>
    </v-radio-group>
      <div class="text-center mt-4">
        <slot></slot>
      </div>
  </div>
</template>

<script>
import CalculatedPrice from '@/components/Molecules/CalculatedPrice';
export default {
  name: 'BuyAtMarketOffers',
  components: {
    CalculatedPrice,
  },
  props: {
    marketPrice: Number,
    data: Array,
  },
  data() {
    return {
      singleExpand: true,
      selectedCollector: 'Select this collector in the row and make your offer',
      offerCollector: null,
      frames: 0,
      offer: 0,
      currency: '',
      selected: false,
      headers: [
        {
          text: 'Collector',
          align: 'start',
          sortable: false,
          value: 'collector',
          width: '35%',
        },
        { text: 'Frame Price', value: 'offer', width: '42%', align: 'rigth' },
        { text: 'Quantity', value: 'quantity', width: '28%' },
      ],
    };
  },
  computed: {
    calculatedPrice() {
      return {
        Frames: this.frames,
        'Prices per Frame': this.offer,
        Total: this.frames * this.offer,
      };
    },
  },
  methods: {
    selectOffer(offer) {
      this.offerCollector = offer.collector;
      this.frames = offer.quantity;
      this.offer = offer.offer;
      this.selected = true;
      this.currency = offer.currency;
    },
    getColor(diff) {
      if (diff < 0) return 'red';
      else if (diff > 0) return 'green';
      else return 'grey';
    },
    getIcon(diff) {
      if (diff < 0) return 'mdi-arrow-up-bold';
      else if (diff > 0) return 'mdi-arrow-down-bold';
      else return 'mdi-arrow-right-bold';
    },
  },
};
</script>
<style>
.neo-table table {
  table-layout: fixed;
}
</style>

<style lang="scss" scoped>
.neo-card {
  max-width: 360px;
  margin: 16px auto;
}
</style>
